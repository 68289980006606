// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiUrl: 'https://igo-backend-staging.autocab.net',
    domain: 'https://igoportal-staging.autocab.net/#/',
    name: 'Staging',
    mapAccessToken: 'gkMeBdbx2SEppoWbpVuLGncTehuUEsxs5KDOi8Id5Y_yXlt6B7iaCnHOCMf_szZBspfVdV5Il47r5foLDI9mErlrPIaHtbAo5l_C',
    showDeveloperTools: true,
    buildNumber: 'development.903',
    azureClientId: '149c4cec-e9fb-44fb-9114-c92e9433b70b',
    azureClientURI: 'api://149c4cec-e9fb-44fb-9114-c92e9433b70b',
    cxAdminUrl: 'https://autocab-staging-igoportal.azurewebsites.net/admin'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
